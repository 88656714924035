<template>
    <div class=" bg-blue-100 flex flex-col justify-start items-center w-full min-h-screen font-script">
        <template v-if="loading">
            <div class="flex justify-center">
                <img src="/img/Wedges-3s-200px.svg" style="height: 100px; width: 100px;">
            </div>
        </template>
        <template v-if="!loading">
            <template v-if="!checkList">
                <list-no-own v-on:createdList="getLists" />
            </template>
            <template v-if="Nolists">
                <list-none />
            </template>
            <template v-else>
                <div v-for="list in lists" :key="list.id" class="w-full px-4 last:mb-16">
                    <list-item :list="list" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
// import { supabase } from "../../supabase";
import { mapActions, mapState} from 'vuex';
import ListItem from './ListItem.vue';
import ListNone from './ListNone.vue';
import ListNoOwn from './ListNoOwn.vue';
export default {
  components: { ListItem, ListNone, ListNoOwn },
    name: 'listAll',
    data() {
        return {
            Nolists: false,
        }
    },
    beforeMount() {
        this.initLists();
        this.checkHaveList();
    },
    computed:{
        ...mapState(['lists', 'user', 'loading']),
        checkList() {
            let ownList = true;
            if (this.lists?.length >= 0) {
                ownList = false;
                this.lists.map(list => {
                    if (list.profile_id === this.user.id) {
                        ownList = true;
                    }
                });
            }
            return ownList;
        },
    },
    methods: {
        checkHaveList(){
            if (!this.lists){
                return this.Nolists = false;
            }
            if (this.lists.length === 0) {
                return this.Nolists = true;
            }
        },
        ...mapActions(['initLists', 'getLists'])
    },
}
</script>