<template>
    <div>
        <list-all />
        <navbar />
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import ListAll from '../components/lists/ListAll.vue'
export default {
    name: 'Lists',
    components: { ListAll, Navbar },
}
</script>