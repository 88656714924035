<template>
    <div class="text-gray-700 font-bold text-3xl text-center flex flex-col justify-center items-center">
        <p class="mb-4">Pas de liste pour le moment...</p>
        <img src="https://i.giphy.com/media/2pjspMQCi70k/giphy.webp" >
    </div>
</template>

<script>
export default {
    name: 'listNone'
}
</script>