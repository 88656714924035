<template>
    <div>
        <button class="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 font-script" type="button" style="transition: all .15s ease" v-on:click="toggleModal()">
            Créer une liste
        </button>
        <div v-if="showEdit" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-full" style="max-width: 600px">
                <div class="relative px-4 py-10 bg-white shadow rounded-3xl sm:p-10">
                    <div class="max-w-md mx-auto">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                                <h2 class="leading-relaxed">Création de ta liste de Noël</h2>
                                <p class="text-sm text-gray-500 font-normal leading-relaxed">Tu va pouvoir recevoir des cadeaux !</p>
                            </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                                <h2 class="leading-relaxed">Sélectionne l'image de ta liste :</h2>
                            </div>
                        </div>
                        <div>
                        <div class=" grid grid-cols-3 gap-4">
                            <div v-for="n in 9" :key="n">
                                <img :src="getImage(n)" class="object-cover rounded" @click="selectImage(n)" :id="getId(n)">
                            </div>
                        </div>
                        <div v-if="errorMessage" class="flex justify-center items-center mt-2">
                            <p class="text-red-500">{{errorMessage}}</p>
                        </div>
                        <div class="pt-4 flex items-center space-x-4">
                            <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Annuler
                            </button>
                            <button v-on:click="createOwnList" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Créer</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showEdit" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'listCreate',
    data(){
        return {
            showEdit: false,
            focusItem: null,
            errorMessage: null,
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods:{
        toggleModal(){
            this.showEdit = !this.showEdit;
            this.focusItem = null;
            this.errorMessage = null;
        },
        getImage(index) {
            return `/img/lists${index}.jpg`;
        },
        getId(index) {
            return `lists${index}`;
        },
        selectImage(index) {
            this.errorMessage = null;
            const images = document.querySelectorAll('img');
            for (const image of images) {
                if (image.id === this.getId(index)) {
                    image.classList.add('border-4', 'border-blue-500');
                    this.focusItem = image.id;
                } else {
                    image.classList.remove('border-4', 'border-blue-500');
                }
            }
        },
        createOwnList(){
            if(this.focusItem){
                const list = this.createList(this.focusItem);
                list.then(() => {
                    this.toggleModal();
                })
                .catch(() => {
                    return this.errorMessage = "❌ Erreur lors de la création de la liste";
                });
            }
        },
        ...mapActions(['createList'])
    },
}
</script>
<style scoped>
    img {
        width: 100px;
        height: 100px;
    }
</style>>
