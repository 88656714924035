<template>
    <div class=" flex flex-row bg-gray-50 bg-opacity-90 w-full h-36 rounded-xl mt-4">
        <template v-if="loading">
            <div class="flex justify-center items-center w-full h-full bg-white rounded-xl">
                <img src="/img/Wedges-3s-200px.svg" style="height: 100px; width: 100px;">
            </div>
        </template>
        <template v-if="!loading">
            <img loading="lazy" :src="getImage()" class=" w-1/2 h-full object-cover rounded-l-xl" />
            <div class="w-1/2 h-full flex flex-col justify-around items-center p-2">
                <h3 class="text-black font-script text-xl">Liste de {{username}}</h3>
                <button @click="toEditOrSee" class="bg-gradient-to-r from-blue-600 to-blue-500  text-white py-2 px-4 rounded hover:from-blue-500 hover:to-blue-400">{{edit ? 'Editer ma liste' : 'Voir'}}</button>
            </div>
        </template>
    </div>
</template>

<script>
import { supabase } from "../../supabase";
import { mapState, mapActions } from 'vuex';
export default {
    name: 'listItem',
    props: {
        list: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            username: '',
            edit: false,
            loading: false,
        }
    },
    beforeMount() {
        this.getName();
    },
    computed:{
        ...mapState(['user'])
    },
    methods: {
        getImage() {
            return `/img/${this.list.image_cover_id}.jpg`;
        },
        getName() {
            this.loading = true;
            if (this.list?.profile_id) {
                supabase.from('profiles').select('username, id').eq('id', this.list.profile_id).limit(1)
                .then(res => {
                    if (res.status === 200) {
                        this.username = res.data[0].username;
                        this.getEdit(res.data[0].id);
                        this.loading = false;
                    }
                })
            }
        },
        getEdit() {
            if (this.list?.profile_id === this.user.id) {
                return this.edit = true;
            }
        },
        toEditOrSee() {
            if (this.edit) {
                return this.$router.push({ name: 'editList', params: { id: this.list.id }});
            }
            return this.$router.push({ name: 'viewList', params: { id: this.list.id }});
        },
        ...mapActions(['getFamilyMembers', 'getFamilyMemberName'])
    },
}
</script>