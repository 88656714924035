<template>
    <div class=" flex flex-row bg-red-500 w-full h-36">
        <div class="w-full h-full flex flex-col justify-around items-center p-2">
            <h3 class="text-white text-xl font-script font-bold">⚠️ Tu n'as pas de liste</h3>
            <list-create />
        </div>
        <img src="../../../public/img/nolist.gif" class=" w-1/2 h-full object-cover rounded-r-xl" />
    </div>
</template>

<script>
import ListCreate from './ListCreate.vue';
export default {
  components: { ListCreate },
    name: 'listNoOwn',
}
</script>